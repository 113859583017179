/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    strong: "strong",
    ol: "ol",
    br: "br"
  }, _provideComponents(), props.components), {Specialization, Skill, Boon, Item, Attribute, Beginner, Information, Divider, Grid, GridItem, Traits, Card, Advanced, Effect, Trait, Condition, Control, Video} = _components;
  if (!Advanced) _missingMdxReference("Advanced", true);
  if (!Attribute) _missingMdxReference("Attribute", true);
  if (!Beginner) _missingMdxReference("Beginner", true);
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Card) _missingMdxReference("Card", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!Effect) _missingMdxReference("Effect", true);
  if (!Grid) _missingMdxReference("Grid", true);
  if (!GridItem) _missingMdxReference("GridItem", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Traits) _missingMdxReference("Traits", true);
  if (!Video) _missingMdxReference("Video", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(Specialization, {
    text: "Banner Berserker",
    name: "Berserker"
  }), " is a strong build for ", React.createElement(Specialization, {
    name: "Warrior"
  }), " in fractals. It has excellent defiance bar damage in ", React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), " and ", React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), ", the build also has medium burst and excellent sustained damage. ", React.createElement(Specialization, {
    name: "Warrior"
  }), " can provide half of the necessary ", React.createElement(Boon, {
    name: "Might"
  }), " and ", React.createElement(Boon, {
    name: "Fury"
  }), " to the group by using ", React.createElement(Skill, {
    name: "Forgreatjustice",
    id: "14403"
  }), ". The ", React.createElement(Specialization, {
    text: "Banner Berserker",
    name: "Berserker"
  }), " excels at fighting larger groups of mobs due to extreme cleave with greatswords ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " -> ", React.createElement(Skill, {
    name: "bloodreckoning",
    id: "30189"
  }), " -> ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " combo."), "\n", React.createElement(_components.p, null, "Unfortunately, this builds requires a lot of Assassin pieces or other runes than ", React.createElement(Item, {
    name: "scholar",
    id: "24836"
  }), " due to receiving very little ", React.createElement(Attribute, {
    name: "Precision"
  }), " from traits. This is also the reason why the ", React.createElement(Specialization, {
    text: "Banner Berserker",
    name: "Berserker"
  }), " is one of the classes that benefit a lot from higher ", React.createElement(Attribute, {
    name: "Agony Resistance"
  }), "."), "\n", React.createElement(_components.p, null, "Furthermore, the build is able to use damage modifying sigils like ", React.createElement(Item, {
    name: "Impact",
    type: "Sigil",
    id: "24868"
  }), " as well as slaying potions such as ", React.createElement(Item, {
    name: "Powerful Potion of Slaying Scarlets Armies",
    id: "50082"
  }), ". As a ", React.createElement(Specialization, {
    text: "Banner Berserker",
    name: "Berserker"
  }), " your focus is to maintain your unique party buffs and instantly contribute to breaking defiance bars, all while making DPS players sweat nervously when they glance at their DPS meter."), "\n", React.createElement(Beginner, null, React.createElement(_components.p, null, "Before progressing to the advanced version of this page, we recommend acquiring an in-depth understanding of your CC skills and when to use them efficiently. You should be able to hit consistently high DPS, which you can verify with ", React.createElement(_components.a, {
    href: "https://www.deltaconnected.com/arcdps/"
  }, "arc-dps"), ". And finally, you should be able to execute the basic step-by-step rotation provided below without spending too much thought on it."), React.createElement(_components.p, null, "The advanced page provides a more complete overview of all the utilities the ", React.createElement(Specialization, {
    name: "Warrior"
  }), " has to offer. If you ever feel like you are missing information feel free to already swap to the advanced page!"), React.createElement(Information, null, React.createElement(_components.p, null, "Although this build lists 162 ", React.createElement(Attribute, {
    name: "Agony Resistance"
  }), ", it is not a strict requirement! We recommend getting started with this build anyway - even if it might be suboptimal while being in Tier 1 or 2 fractals. Keep upgrading your ", React.createElement(Attribute, {
    name: "Agony Resistance"
  }), " until you reach the suggested variant. In the beginning, it is much more important to learn about fractal mechanics and your skills rather than equipping optimal gear."))), "\n", React.createElement(Divider, {
    text: "Equipment"
  }), "\n", React.createElement(Divider, {
    text: "Build"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "7"
  }, React.createElement(Traits, {
    traits1Id: "4",
    traits1: "Strength",
    traits1Selected: "Peak Performance,Great Fortitude,Berserkers Power",
    traits2: "Discipline",
    traits2Selected: "Warriors Sprint,Destruction of the Empowered,Axe Mastery",
    traits3Id: "18",
    traits3: "Berserker",
    traits3Selected: "Savage Instinct,Blood Reaction,Bloody Roar",
    traits1Id: "4",
    traits3Id: "18",
    traits1SelectedIds: "1444,1449,1437",
    traits2Id: "51",
    traits2SelectedIds: "1413,1489,1369",
    traits3SelectedIds: "1977,2011,1928"
  }), React.createElement(Card, {
    title: "Extra Weapons"
  }, React.createElement(_components.p, null, "Absolutely mandatory: a greatsword for mobility and cleave."), React.createElement(Advanced, null, React.createElement(_components.p, null, "Nice to have:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Axes with ", React.createElement(Item, {
    name: "Night",
    type: "Sigil",
    disableText: true,
    id: "36053"
  }), ", ", React.createElement(Item, {
    name: "Serpent Slaying",
    type: "Sigil",
    disableText: true,
    id: "24658"
  }), " and other slaying sigils."), "\n", React.createElement(_components.li, null, "Warhorn for pre-stacking ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n", React.createElement(_components.li, null, "Sword for out of combat mobility."), "\n", React.createElement(_components.li, null, "Rifle for Siax (coordinated)."), "\n", React.createElement(_components.li, null, "Torch (trade CC for DPS or a fire field)."), "\n")))), React.createElement(GridItem, {
    sm: "5"
  }, React.createElement(Card, {
    title: "Situational Skills"
  }, React.createElement(Beginner, null, React.createElement(_components.p, null, "|                                                           |                                                                                                    |\n| --------------------------------------------------------- | -------------------------------------------------------------------------------------------------- | --- |\n| ", React.createElement(Skill, {
    name: "For Great Justice!",
    size: "big",
    disableText: true,
    id: "14403"
  }), " | Whenever you notice less than 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " on your buff bar.                  |\n| ", React.createElement(Skill, {
    name: "bloodreckoning",
    size: "big",
    disableText: true,
    id: "30189"
  }), "     | For double ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " or the situational ", React.createElement(Specialization, {
    name: "Berserker"
  }), " build. |\n| ", React.createElement(Skill, {
    name: "Wild Blow",
    size: "big",
    disableText: true,
    id: "29941"
  }), "          | 332 CC and 4s ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " extension.                                                   |\n| ", React.createElement(Skill, {
    name: "Battle Standard",
    size: "big",
    disableText: true,
    id: "14419"
  }), "    | Can be used on Arkk if your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " is able to solo the CC bar.          |     |")), React.createElement(Advanced, null, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "For Great Justice!",
    size: "big",
    disableText: true,
    id: "14403"
  })), React.createElement(_components.td, null, "Whenever you notice less than 25 stacks of ", React.createElement(Boon, {
    name: "Might"
  }), " on your buff bar.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of fury",
    size: "big",
    disableText: true,
    id: "14410"
  })), React.createElement(_components.td, null, "Grants ", React.createElement(Attribute, {
    name: "Precision",
    text: "180 Precision"
  }), " passively; grants 360 ", React.createElement(Attribute, {
    name: "Precision"
  }), " and ", React.createElement(Attribute, {
    name: "Ferocity"
  }), " on use and fills up the entire adrenalin bar.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Signet of might",
    size: "big",
    disableText: true,
    id: "14404"
  })), React.createElement(_components.td, null, "Flat 180 power buff. Only take this when no other utility is needed.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Battle Standard",
    size: "big",
    disableText: true,
    id: "14419"
  })), React.createElement(_components.td, null, "Can be used on Arkk if your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " is able to solo the CC bar.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "onmymark",
    size: "big",
    disableText: true,
    id: "14575"
  })), React.createElement(_components.td, null, "Can be used to pull Krait from the side altars at the Nightmare CM fractal.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "featherfootgrace",
    size: "big",
    disableText: true,
    id: "45380"
  })), React.createElement(_components.td, null, "Grants ", React.createElement(Effect, {
    name: "Superspeed"
  }), " for skips.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "bloodreckoning",
    size: "big",
    disableText: true,
    id: "30189"
  })), React.createElement(_components.td, null, "For double ", React.createElement(Skill, {
    name: "arcdivider",
    id: "29852"
  }), " or the situational ", React.createElement(Specialization, {
    name: "Berserker"
  }), " build.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "outrage",
    size: "big",
    disableText: true,
    id: "30258"
  })), React.createElement(_components.td, null, "Very strong in conjunction with ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), " for ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " extension.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "Wild Blow",
    size: "big",
    disableText: true,
    id: "29941"
  })), React.createElement(_components.td, null, "332 CC and 4s ", React.createElement(Skill, {
    name: "berserk",
    id: "30185"
  }), " extension.")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "bullscharge",
    size: "big",
    disableText: true,
    id: "14516"
  })), React.createElement(_components.td, null, "300 CC, gives ", React.createElement(Trait, {
    name: "Peakperformance",
    id: "1444"
  }), ".")))))))), "\n", React.createElement(Divider, {
    text: "Details"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    sm: "8"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "How to deal damage:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " as often as possible. Three crits are required to gain a single ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), ":", "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Cycloneaxe",
    id: "14421"
  }), " and ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Dualstrike",
    id: "14418"
  }), " and ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Get three ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), " before pressing ", React.createElement(Skill, {
    name: "Whirling axe",
    id: "14399"
  }), "."), "\n", React.createElement(_components.li, null, "If the boss has a breakbar, make sure to use ", React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), " to proc ", React.createElement(Item, {
    name: "severance",
    id: "84505"
  }), ", then swap to Axe/Axe."), "\n"), React.createElement(Advanced, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Only use ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " outside ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " to prevent its ammo count fully recharging."), "\n", React.createElement(_components.li, null, "Interrupting your auto-attack chain to use ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " or enter ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " is a DPS increase."), "\n", React.createElement(_components.li, null, "Keep in mind that ", React.createElement(Skill, {
    name: "Crushing Blow",
    id: "14518"
  }), " inflicts ", React.createElement(Condition, {
    name: "Vulnerability",
    count: 10
  }), "! This can be often used with no DPS loss if you are on your mace set waiting to CC. Especially useful if you apply the ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " to adds for a quick burst. If the foe is disabled it even inflicts ", React.createElement(Condition, {
    name: "Vulnerability",
    count: 20
  })), "\n", React.createElement(_components.li, null, "You must be in ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " mode for as many burn phases as possible for maximum DPS. Pay attention to your group's phase times and your ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " extensions. It is better for ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " to drop when the boss isn’t ", React.createElement(Effect, {
    name: "Exposed"
  }), " if that means it will be back up right before another burst window."), "\n", React.createElement(_components.li, null, "Think ahead when CC is needed and swap to Maces earlier to avoid losing invaluable ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " time."), "\n")), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Extra notes:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your groups ", React.createElement(Boon, {
    name: "Might"
  }), " is not 25, take ", React.createElement(Skill, {
    name: "Forgreatjustice",
    id: "14403"
  }), ". It is a bigger DPS increase than ", React.createElement(Skill, {
    name: "signetofmight",
    id: "14404"
  }), "!"), "\n", React.createElement(_components.li, null, "If you expect your party to be slow it can make sense to bring ", React.createElement(Trait, {
    name: "smashbrawler",
    id: "2049"
  }), " and eat the initial ", React.createElement(Control, {
    name: "Stun"
  }), " of ", React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  }), "."), "\n", React.createElement(_components.li, null, "Casting ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " for ", React.createElement(Trait, {
    name: "Peak Performance",
    id: "1444"
  }), " is only a DPS gain if you can’t be hitting something else, use it right before the start of DPS phases but only use it during if you need the heal."), "\n", React.createElement(_components.li, null, "Entering ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " grants three stacks of ", React.createElement(Trait, {
    name: "Berserkers Power",
    id: "1437"
  }), ", while ", React.createElement(Skill, {
    name: "Decapitate",
    id: "30851"
  }), " only grants one. You will find ", React.createElement(Trait, {
    name: "Berserkers Power",
    id: "1437"
  }), " starting to run low when out of ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), ". Try to make sure you are in ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " for burn phases while also not delaying ", React.createElement(Skill, {
    name: "Berserk",
    id: "30185"
  }), " where possible."), "\n")), React.createElement(GridItem, {
    sm: "4"
  }, React.createElement(Card, {
    title: "CC skills"
  }, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th), React.createElement(_components.th))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "wildblow",
    id: "29941"
  })), React.createElement(_components.td, null, "332 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "headbutt",
    id: "30343"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "bullscharge",
    id: "14516"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14502",
    id: "14502"
  })), React.createElement(_components.td, null, "150 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    name: "skullgrinder",
    id: "29679"
  })), React.createElement(_components.td, null, "100 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14414",
    id: "14414"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14415",
    id: "14415"
  })), React.createElement(_components.td, null, "300 damage")), React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(Skill, {
    id: "14503",
    id: "14503"
  })), React.createElement(_components.td, null, "100 damage")))), React.createElement(_components.p, null, React.createElement(_components.strong, null, "When to take Mace:")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In fights with only one defiance bar, in the beginning, use Axe/Mace."), "\n", React.createElement(_components.li, null, "In T4s and encounters that have defiance bars during the fight, use an offhand mace only."), "\n")))), "\n", React.createElement(Divider, {
    text: "Rotation / Skill usage"
  }), "\n", React.createElement(Grid, null, React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Information"
  }, React.createElement(_components.p, null, "Golem rotations out of the raid builds are generally suboptimal in fractals due to ", React.createElement(Effect, {
    name: "Exposed"
  }), " and phases being much shorter compared to raids. The raid rotations are optimized for sustained DPS while in fractals a player needs the ability to adapt a rotation to the amount of time a group needs to finish a phase.", React.createElement(_components.br), "\n", "For that reason, you find suggestions for pressing your axe skills in different estimated phase lengths here.")), React.createElement(Advanced, null, React.createElement(Card, {
    title: "Berserker openers"
  }, React.createElement(Video, {
    caption: "by Decados [dT], edited by Vince [dT]",
    youtube: "6z6ZvHxHXek"
  })))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Precasting"
  }, React.createElement(Beginner, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Stack ", React.createElement(Boon, {
    name: "Might"
  }), ": Use both charges of ", React.createElement(Skill, {
    name: "For Great Justice!",
    id: "14403"
  }), "."), "\n", React.createElement(_components.li, null, "If there is a defiance bar present immediately at the start of the fight, start on your Mace set - otherwise swap to it when necessary."), "\n", React.createElement(_components.li, null, "With ", React.createElement(Item, {
    name: "Paralyzation",
    type: "Sigil",
    id: "24639"
  }), " you can deal 690 defiance bar damage with:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), ", you can precast it and cancel the aftercast animation."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " with ", React.createElement(Item, {
    name: "Paralyzation",
    type: "Sigil",
    id: "24639"
  }), " for a total of 390 CC."), "\n"), "\n"), "\n")), React.createElement(Advanced, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Stack ", React.createElement(Boon, {
    name: "Might"
  }), ":", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use both charges of ", React.createElement(Skill, {
    name: "For Great Justice!",
    id: "14403"
  }), "."), "\n", React.createElement(_components.li, null, "Drop a fire field with ", React.createElement(Skill, {
    name: "Flames of War",
    id: "29940"
  }), "."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Call of Valor",
    id: "14394"
  }), " and ", React.createElement(Skill, {
    id: "14393",
    id: "14393"
  }), " on offhand Warhorn to blast ", React.createElement(Boon, {
    name: "Might"
  }), "."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "Precast damage skills:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "(optional) ", React.createElement(Skill, {
    name: "Flames of War",
    id: "29940"
  })), "\n", React.createElement(_components.li, null, "(optional) ", React.createElement(Skill, {
    id: "14393",
    id: "14393"
  }), " for two stacks of a 25% damage modifier"), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "Mending",
    id: "14401"
  }), " just before the boss becomes vulnerable."), "\n"), "\n"), "\n", React.createElement(_components.li, null, "If there is a defiance bar present immediately at the start of the fight, start on your Mace set - otherwise swap to it when necessary."), "\n", React.createElement(_components.li, null, "With ", React.createElement(Item, {
    name: "Paralyzation",
    type: "Sigil",
    id: "24639"
  }), " you can deal 690 defiance bar damage with:", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Tremor",
    id: "14415"
  }), ", you can precast it and cancel the aftercast animation."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Headbutt",
    id: "30343"
  }), " with ", React.createElement(Item, {
    name: "Paralyzation",
    type: "Sigil",
    id: "24639"
  }), " for a total of 390 CC."), "\n"), "\n"), "\n")))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Skill usage"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Ultra short phases (3 seconds):")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), React.createElement(_components.p, null, "or depending on the phase length:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Short phases (6 seconds):")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "chop",
    id: "14369"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "Longer phases (>10 seconds):")), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "whirlingaxe",
    id: "14399"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "cycloneaxe",
    id: "14421"
  }), ", ", React.createElement(Skill, {
    name: "chop",
    id: "14369"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Throw axe",
    profession: "warrior",
    id: "14398"
  }), ", ", React.createElement(Skill, {
    name: "dualstrike",
    id: "14418"
  }), " and ", React.createElement(Skill, {
    name: "decapitate",
    id: "30851"
  }), "."), "\n"))), React.createElement(GridItem, {
    xs: "12",
    sm: "6"
  }, React.createElement(Card, {
    title: "Golem Rotation"
  }, React.createElement(Video, {
    youtube: "NkoFpwfcxfw",
    caption: "by TheCryophoenix.4587"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
